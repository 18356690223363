import React, { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Tabs, Tab, Form, Row, Col, FormControl, ButtonGroup, Button } from 'react-bootstrap'
import { getOneSession, getStudioByUri } from '../../../services'
import Error403 from '../../Errors/403'
import Error404 from '../../Errors/404'
import Invite from './Invite'
import Invited from './Invited'
import { userList } from '../../../utils'
import './style.scss'
import { TitleContext } from '../../../Context'

export default ({
  sessionId, studioUri
}) => {
  const [session, setSession] = useState(null)
  const [studio, setStudio] = useState(null)
  const [permitted, setPermitted] = useState(true)
  const { session_id, studio_uri } = useParams()
  const [tabKey, setTabKey] = useState('invite')
  const [position, setPosition] = useState('session_runner')
  const [sessionDate, setSessionDate] = useState('')
  const [loading, setLoading] = useState(true)
  const [willWorkAs, setWillWorkAs] = useState([false, false])
  const [name, setName] = useState('')
  const [invitedOnly, setInvitedOnly] = useState(false)
  const context = useContext(TitleContext)

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const std = await getStudioByUri(studio_uri || studioUri)
      const ses = await getOneSession(session_id || sessionId)
      setPermitted(true)
      setSession(ses)
      setStudio(std)
      setSessionDate(ses.dates[0]._id)
    } catch (err) {
      console.log('err: ', err);
    }
    setLoading(false)
  }, [session_id, studio_uri, studioUri, sessionId])
  useEffect(() => {
    loadData()
  }, [session_id, studio_uri, studioUri, sessionId])

  useEffect(() => {
    const title = `${studio ? studio.name : ''}`
    document.title = title
    context.setTitle(title)
  }, [studio])

  if (loading) {
    return <div>
      Loading...
    </div>
  }

  if (!session || !studio) {
    return <Error404 />
  }

  if (!permitted) {
    return <Error403 />
  }

  const sessionDates = session.dates

  return (
    <div className="freelancer-invite-page">
      <div className='d-flex border-bottom mb-3 mt-4 pb-3 flex-wrap'>
        <h1 className="mb-0 h-32-400 mr-auto">{studio.name} {session.name} Freelancer Invite</h1>
        <div className='d-flex detail-table'>
          <table className="session-info">
            <thead>
              <tr>
                <th className="pl-4">Casting Director</th>
                <th className="pl-4">Session Dates</th>
              </tr>
            </thead>
            <tbody>
              {sessionDates.map((date, idx) => {
                return (
                  <tr key={date._id}>
                    <td className='pl-4'>
                      {idx === 0 && studio.casting_directors.map(c => {
                        return c.email
                      })}
                      {studio.casting_directors.length === 0 && '-'}
                    </td>
                    <td className='pl-4'>
                      {moment(new Date(date.start_time)).format('MM/DD')}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-3">
        <Row className='mb-3'>
          <Col md={3} className='mb-2'>
            <FormControl
              placeholder="Search by Name"
              value={name}
              onChange={(e) => { setName(e.target.value) }}
            />
          </Col>
          <Col
            md={'auto'}
            className="d-flex align-items-center text-10 flex-wrap mb-2"
          >
            <Form.Label className="my-0 mr-3">Will Work As</Form.Label>
            <div>
              <Form.Check
                label="Session Runner"
                inline
                onChange={(e) => { setWillWorkAs([e.target.checked, willWorkAs[1]]) }}
                checked={willWorkAs[0]}
              />
              <Form.Check
                label="Lobby Manager"
                inline
                onChange={(e) => { setWillWorkAs([willWorkAs[0], e.target.checked]) }}
                checked={willWorkAs[1]}
              />
            </div>
          </Col>
          <Col
            md={3}
            className="d-flex align-items-center text-10"
          >
            <ButtonGroup>
              <Button
                variant={!invitedOnly ? 'danger': 'outline-danger'}
                checked={!invitedOnly}
                onClick={() => {
                  setInvitedOnly(!invitedOnly)
                }}
              >
                All
              </Button>
              <Button
                variant={invitedOnly ? 'danger': 'outline-danger'}
                checked={invitedOnly}
                onClick={() => {
                  setInvitedOnly(!invitedOnly)
                }}
              >
                Invited
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Invite
          session={session}
          position={position}
          sessionDate={sessionDate}
          name={name}
          willWorkAs={willWorkAs}
          invitedOnly={invitedOnly}
        />
      </div>
    </div>
  )
}
