import React, { useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import ReactPlayer from 'react-player'
import { FaComment, FaSpinner } from 'react-icons/fa'
import {
  static_root,
  getManyByTalent,
  likeVideoById,
  getUser,
  addCommentToVideo,
  getSessionCommentsByVideoId,
  deleteSessionCommentById
} from '../../services'
import './recordmodel.scss'
import { CloseIconBlack, CloseIconFIll, LikeIcon, LikeIconSm, LikeIconSmBlack, SmallTrashIcon } from '../../assets/icons/session'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Avatar from 'react-avatar'
import moment from 'moment'
import { joinRoom } from '../../ws'

const RecordVideosModal = ({ record, likedVideosFilter, candidates, setVideoRecord }) => {
  const [loading, setLoading] = useState(true)
  const [recordVideos, setRecordVideos] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [likeLoading, setLikeLoading] = useState(false)
  const [likeVideoTooltipClosed, setLikeVideoTooltipClosed] = useState(false)
  const [comment, setComment] = useState('')
  const [videoComments, setVideoComments] = useState([])

  const user = getUser()

  useEffect(() => {
    setLikeVideoTooltipClosed(localStorage.getItem('likeVideoTooltipClosed'))
  }, [])

  const showRecordVideosModal = async (recordId) => {
    const videos = await getManyByTalent(recordId)
    if (likedVideosFilter) {
      setRecordVideos(videos.filter(video => video.liked))
    } else {
      setRecordVideos(videos)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (record) {
      showRecordVideosModal(record._id)
    }
  }, [record])

  useEffect(() => {
    if (loading) { return }
    setTimeout(() => {
      const video = document.querySelector('#active-player video')
      if (video) {
        video.play()
        video.addEventListener('ended', () => {
          if (selectedIndex < recordVideos.length - 1) {
            setSelectedIndex(selectedIndex + 1)
          }
        })
      }
    }, 1000)
  }, [selectedIndex, loading])

  const selectedVideo = recordVideos[selectedIndex]

  const loadComments = async () => {
    const comments = await getSessionCommentsByVideoId(selectedVideo._id)
    if (comments) {
      setVideoComments(comments)
    } else {
      setVideoComments(selectedVideo.session_comments)
    }
  }

  useEffect(() => {
    if (selectedVideo) {
      loadComments()
      joinRoom(selectedVideo._id)
      document.body.addEventListener('ws-message', wsMessageHandler)
    }
    return () => {
      document.body.removeEventListener('ws-message', wsMessageHandler)
    }
  }, [selectedVideo])

  const wsMessageHandler = (event) => {
    switch (event.detail.type) {
      case 'session-comment-video':
        loadComments()
        break
    }
  }

  const likeVideo = async (videoItem, currentIndex) => {
    try {
      setLikeLoading(true)
      const recordIdArray = videoItem.group.records.map(record => record._id)
      await likeVideoById(videoItem._id, !videoItem.liked, recordIdArray)
      recordVideos[currentIndex].liked = !recordVideos[currentIndex].liked
      const currentCandidateIndex = candidates.findIndex(candidate => candidate._id === record._id)

      const recordHadLikedVideos = recordVideos.some(video => video.liked)

      if (recordHadLikedVideos) {
        candidates[currentCandidateIndex].hasLikedVideos = true
      } else {
        candidates[currentCandidateIndex].hasLikedVideos = false
      }
      setLikeLoading(false)
    }
    catch (err) {
      console.log('liking video error :>> ', err);
    }
  }

  const onClickToolTipClose = () => {
    localStorage.setItem('likeVideoTooltipClosed', true);
    setLikeVideoTooltipClosed(true)
  }

  function pad(n) {
    return (n < 10) ? ("0" + n) : n;
  }
  const addNewComment = async () => {
    try{
      const commentObject = {
        by: user.id,
        content: comment,
        created_at: new Date()
      }
      await addCommentToVideo(selectedVideo._id, commentObject)
      setComment('')
    } catch (error) {
      console.error('Error adding comment:', error)
    }
  }

  const deleteComment = async (commentId) => {
    try{
      await deleteSessionCommentById(selectedVideo._id, commentId)
    }catch (error) {
      console.error('Error deleting comment:', error)
    }
  }

  if (!record) {
    return null
  }

  if (loading) {
    return <FaSpinner />
  }


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span className='h-12-400'>Please note that likes are global, not specific to individual users. We recommend designating one person to manage and mark likes.</span>
      <button className='close-button' onClick={onClickToolTipClose}><CloseIconBlack /></button>
    </Tooltip>
  );

  return (
    <div className='react-player-wrap p-4 m-4'>
      <button className='video-close-button' onClick={() => setVideoRecord(null)}>
        <CloseIconFIll />
      </button>
      <div>
        <div className="row record-videos-modal">
          {selectedVideo &&
            (
              (!likeVideoTooltipClosed || likeVideoTooltipClosed === "false") ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 50000 }}
                  overlay={renderTooltip}
                >
                  <button
                    className={`like-button ${selectedVideo?.liked ? 'liked' : ''}`}
                    onClick={() => likeVideo(selectedVideo, selectedIndex)}
                  >
                    <LikeIcon />
                  </button>
                </OverlayTrigger>
              ) : (
                <button
                  className={`like-button ${selectedVideo?.liked ? 'liked' : ''}`}
                  onClick={() => likeVideo(selectedVideo, selectedIndex)}
                >
                  <LikeIcon />
                </button>
              )
            )
          }
          <div className="col-12 d-flex">
            {selectedVideo
              ? <ReactPlayer
                key={selectedIndex}
                controls={true}
                url={static_root + selectedVideo.uri}
                id="active-player"
                className="w-100 pb-3 active-video-container"
                height="100%"
              />
              : <div className="text-center py-4">No Video Available.</div>
            }
          </div>
          <div className="col-12">
            <div className='videos-select'>
              {recordVideos.map((video, index) => {
                return (
                  <div
                    key={index}
                    className={classnames("video-item", {
                      selected: index === selectedIndex
                    })}
                    onClick={() => setSelectedIndex(index)}
                  >
                    <div className="index-indicator-white">
                      {pad(index + 1)}
                    </div>
                     
                    <button 
                      title='Like Video'
                      className={`like-button-sm ${video.liked ? 'liked' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        likeVideo(video, index)
                      }}>
                      {video.liked ? <LikeIconSm /> : <LikeIconSmBlack />}
                    </button>
                     
                    <div className='comment-count h-12-400' title='Session Comments'>
                      <FaComment className='mr-1'/> {video.session_comments.length}
                    </div>
                    <img
                      className="dummy-player dummy-video"
                      src={static_root + video.thumbnail}
                    />
                    <div className="labels">
                      {video.postingpage && (
                        <label>Postingpage: {video.postingpage.name}</label>
                      )}
                      {video.is_archived && (
                        <label>Archived</label>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='session-comments-container'>
        <h3 className='h-14-700 primary-color'>Take {selectedIndex+1} Comments</h3>
        <div className={'note-container comments-list'}>
          {videoComments.length > 0 && videoComments.map((comment) => (
            <div key={comment._id} className="note-item d-flex aling-items-center p-1">
              <div className="left">
                <Avatar
                  className="participantAvatar"
                  name={comment.by?.first_name + " " + comment.by?.last_name}
                  size="32px"
                  round="32px"
                  textSizeRatio={2}
                  // @ts-ignore
                  title={false}
                />
              </div>
              <div className="ml-2 right">
                <div className="comment-author d-flex align-items-center ">
                  <span className="h-12-600">
                    {comment.by?.first_name} {comment.by?.last_name}
                  </span>
                  {comment?.created_at ? <span className="h-12-400 ml-2 secondary-color">{moment(comment?.created_at).format('MM/DD/YYYY h.mma')}</span> : null}
                </div>
                <span className="h-14-400">{comment.content}</span>
              </div>
              {(user.id === comment.by?._id) && (
                <button
                  title='Remove Comment'
                  className='btn primary-btn session-trash-btn ml-auto'
                  onClick={() => deleteComment(comment._id)}
                  disabled={user.id !== comment.by?._id}>
                  <SmallTrashIcon />
                </button>
              )}
            </div>
          ))}
          {videoComments.length === 0 && (
            <span className='h-12-600'>
              No comments yet.
            </span>
          )}
        </div>
        <div className='d-flex flex-column mt-3'>
          <textarea
            placeholder="Add your comment"
            rows="3"
            cols="50"
            className="comment-textarea br-8 h-14-400 p-2 form-control"
            value={comment}
            onChange={ev => setComment(ev.target.value)}
          ></textarea>
          <button
            className="h-14-700 primary-outline-button my-2 ml-auto text-primary"
            onClick={addNewComment}
            disabled={!comment}
          >
            Comment
          </button>
        </div>
      </div>
    </div>
  )
}

export default RecordVideosModal
