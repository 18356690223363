import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineOrderedList } from 'react-icons/ai'
import { FaFilm, FaPen, FaUsers } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { IoMdVideocam } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { SESSION_TIME_TYPE } from '../../constants'
import { listByManager } from  '../../services/session'
import SendTalentEmailModal from '../Studio/SendTalentEmailModal'
import SessionCrupdateModal from '../Studio/SessionCrupdateModal'

import './style.scss'
const host = window.location.origin

const SessionList = () => {
  const [ sessions, setSessions ] = useState([])
  const [session, setSession] = useState(null)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(1)
  const [showEditModal, setShowEditModal] = useState(false)
  const [emailCheckinLink, setEmailCheckinLink] = useState('')

  const fetchManySessions = async () => {
    const {sessions, count} = await listByManager(page, pageSize)
    setSessions(sessions)
    setPageCount(Math.ceil(count / pageSize))
  }

  useEffect(() => {
    fetchManySessions()
  }, [page, pageCount])

  return (
    <div className="p-5 w-100">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <label className="h1 mb-0">
          Sessions
        </label>
      </div>
      <div className="list-group mb-4">
        {sessions.map(session => (
          <div key={session._id} className="row mt-1 ml-2 mr-2 px-3 py-2 align-items-end">
            <div className="col-2">
              <h5 className="mb-0">{session.studio.name}</h5>
              <div className='d-flex'>
                <label className="mb-0">{session.name}</label>
                <div className='d-flex flex-wrap ml-2'>
                  {session.dates.map((st, idx) => {
                    return (
                      <a
                        key={idx}
                        className='mr-2 d-flex align-items-center cursor-pointer'
                        title="Send Client Email"
                      >
                        <span className={'mr-0'}>
                          {moment(new Date(st.start_time)).format('MM/DD')}
                          {idx < session.dates.length - 1}
                        </span>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="col-auto">
              <Link
                to={`/studio/${session.studio.uri}/${session._id}`}
                className="text-danger"  target="_blank"
                title="Session Video Chat"
              >
                <IoMdVideocam size={17} />
              </Link>
            </div>
            <div className="col-auto">
              <Link
                to={`/studio/${session.studio.uri}/${session._id}?test=true`}
                className="text-danger"  target="_blank"
                title="Virtual Lobby"
              >
                <FaUsers size={17} />
              </Link>
            </div>
            <div className="col-auto">
              <Link
                to={`/onboard/${session.studio.uri}/${session._id}`}
                className="text-danger"  target="_blank"
                title="Session Checkin"
              >
                <AiOutlineOrderedList size={17} />
              </Link>
            </div>
            <div className="col-auto">
              <Link
                to={`/video/${session.studio.uri}/${session._id}`} 
                className="text-danger" target="_blank"
                title="Video Review"
              >
                <FaFilm size={17} />
              </Link>
            </div>
            <div className="col-auto">
              <span
                title="Send Talent Email"
                className='text-danger' onClick={() => {
                  setSession(session)
                  setEmailCheckinLink(`${host}/onboard/${session.studio.uri}/${session._id}`)
                }}
              >
                <HiOutlineMail size={17} />
              </span>
            </div>
            <div className="col-auto action-wrap">
              <FaPen
                className="mr-2"
                onClick={() => {
                  setSession(session)
                  setShowEditModal(true)
                }}
              />
            </div>
          </div>
        ))}
        {sessions.length === 0 && (
          <div className="p-4">
            No sessions available for you.
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <ul className="mb-0 d-flex pagination">
          <li onClick={() => setPage(Math.max(page - 1, 0))}>
            {'<'}
          </li>
          <li className="mx-2">
            Page {page + 1} / {pageCount}
          </li>
          <li onClick={() => setPage(Math.min(page + 1, pageCount - 1))}>
            {'>'}
          </li>
        </ul>
      </div>

      {session && (
        <SessionCrupdateModal
          show={showEditModal}
          onHide = {()=>{setShowEditModal(false)}}
          session={session}
          studio={session.studio}
        />
      )}
      {session && (
        <SendTalentEmailModal
          show={!!emailCheckinLink}
          onHide={() => {
            setEmailCheckinLink(null)
          }}
          emailCheckinLink={emailCheckinLink}
          studio = {session.studio}
        />
      )}
    </div>
  )
}

export default SessionList

