import React from "react";
import { EditIconDark } from "../../assets/icons/session";


const CustomizeFilter = ({
    likedFilter,
    setLikedFilter,
    likedVideosFilter,
    setLikedVideosFilter,
    showHeadShots,
    setShowHeadShots,
    hideCopied,
    setHideCopied,
    hideViewed,
    setHideViewed,
    showHideViewedInput,
}) => {

    return (
        <div class="dropdown">
            <button
                class="action-button h-14-600 dropdown-toggle"
                type="button" id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <EditIconDark /> Customize
            </button>
            <div class="dropdown-menu dropdown-menu-right pb-0 rounded-lg customize-drop-menu" aria-labelledby="dropdownMenuButton">
                <div className="d-flex align-items-center p-2">
                    <input
                        id='talent-with-liked-videos'
                        type='checkbox'
                        checked={likedFilter}
                        onChange={(e) => setLikedFilter(e.target.checked)}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='talent-with-liked-videos'>Show Only Groups With Liked Videos</label>
                </div>
                <div className="d-flex align-items-center p-2">
                    <input
                        id='show-headshots'
                        type='checkbox'
                        checked={showHeadShots}
                        onChange={(e) => setShowHeadShots(e.target.checked)}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='show-headshots'>Show Headshot</label>
                </div>
                <div className="d-flex align-items-center p-2">
                    <input
                        id='hide-copied'
                        type='checkbox'
                        checked={hideCopied}
                        onChange={(e) => setHideCopied(e.target.checked)}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='hide-copied'>Hide Copied</label>
                </div>
                {showHideViewedInput && <div className="d-flex align-items-center p-2">
                    <input
                        id='hide-viewed'
                        type='checkbox'
                        checked={hideViewed}
                        onChange={(e) => setHideViewed(e.target.checked)}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='hide-viewed'>Hide Viewed</label>
                </div>}
                {/* <div className="d-flex align-items-center p-2">
                    <input
                        id='liked-videos'
                        type='checkbox'
                        checked={likedVideosFilter}
                        onChange={(e) => setLikedVideosFilter(e.target.checked)}
                        disabled={!likedFilter}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='liked-videos'>Show Only Liked Videos</label>
                </div> */}
            </div>
        </div>
    )
}

export default CustomizeFilter;