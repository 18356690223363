import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import {
  SESSION_LOCATION_TYPE,
  SESSION_TIME_TYPE,
} from "../../../../constants";

import {
  CalendarIcon,
  ClockIcon,
  DownArrowIcon,
  PlusIcon,
  UpArrowIcon,
  UserIcon,
} from "../../../../assets/icons/session";
import "./DayItem.scss";
import SendClientEmailModal from "../../../Studio/SendClientEmailModal";

export default ({
  idx,
  oneDate,
  isAdmin,
  setDateField,
  goToFreelancerInvite,
  removeDate,
  setDateIndex,
  studio,
  session,
  updateData,
  validateDate,
}) => {
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();
  const isInPerson = [
    SESSION_LOCATION_TYPE[1],
    SESSION_LOCATION_TYPE[2],
  ].includes(oneDate.location_type);

  const [emailSessionLink, setEmailSessionLink] = useState('')
  const host = window.location.origin

  useEffect(() => {
    setShowForm(!oneDate._id);
  }, [oneDate._id]);

  const onSubmit = async () => {
    console.log('onSubmit')
    if(!oneDate.start_time){
      validateDate(idx, 'Date is required')
      return false
    }
    await updateData();
    setShowForm(false);
  };

  const _removeDate = async (idx) => {
    removeDate(idx);
    setDateIndex(-1);
  
  };

  return (
    <div className="day-item">
      <div className="top">
        <div className="left">
          <span className="first-row">
            Day {idx + 1} -{" "}
            {`${
              oneDate.start_time
                ? moment(oneDate.start_time).format("ddd MMM DD, yyyy")
                : "Date not set"
            }`}
          </span>
          <div className="second-row">
            <CalendarIcon /> {oneDate.location_type}
          </div>
          <div className="second-row">
            <UserIcon /> 
            <span>
              Session Manager:{" "}    
              <strong>
                {oneDate.managers.length > 0 ? oneDate.managers
                  .map((m) => `${m.first_name || ""} ${m.last_name || ""}`)
                  .join(", ") : 'Not Assigned' }
              </strong>,
            </span>
             
            <span>
              Lobby Manager:{" "}  
              <strong>
                {oneDate.lobbyManager.length > 0 ? oneDate.lobbyManager.map(
                  (m) => `${m.first_name || ""} ${m.last_name || ""}`
                ) : 'Not Assigned'}
              </strong>
            </span>
          </div>
        </div>
        <button className="invisible-button" onClick={() => setShowForm(!showForm)}/>
        <div className="right">
          <span className="second-row">
            <ClockIcon /> {oneDate.time_frame_count || 0} Time Frames
          </span>
          
          {oneDate?._id && 
          <div>
            <button
              className="open-schedule-button mr-2"
              onClick={() => setEmailSessionLink(`${host}/studio/${studio.uri}/${session._id}`)}
            >
              Share Date
            </button>
            <button
              className="open-schedule-button"
              onClick={() => {
                history.push(
                  `/session/${studio.uri}/edit/${session._id}/schedule/${oneDate._id}`
                );
              }}
            >
              Open Schedule
            </button>
          </div>}
          <button className="round-button" onClick={() => setShowForm(!showForm)}>
            {showForm ? <UpArrowIcon /> : <DownArrowIcon />}
          </button>
        </div>
      </div>
      <div className={`bottom ${showForm ? "show" : ""}`}>
        <div className="bottom-rows">
          <DateTimePicker
            ref={(rf) => {
              if (rf) {
                const el = ReactDOM.findDOMNode(rf);
                el.tabIndex = -1;
              }
            }}
            disabled={!isAdmin}
            value={oneDate.start_time && new Date(oneDate.start_time)}
            className="input-item"
            onChange={(value) => {
              // value should be a Date object
              if(moment(value).diff(moment().startOf('day')) >=0){
                console.log('value', value)
                setDateField(idx, "start_time", value);
              }
              
            }}
          />
          <div className="d-flex w-50">
            <Form.Control
              as="select"
              className={`input-item mw-150 ${isInPerson ? "mr-2" : ""}`}
              value={oneDate.location_type}
              disabled={!isAdmin}
              onChange={(ev) => {
                setDateField(idx, "location_type", ev.target.value);
              }}
            >
              {SESSION_LOCATION_TYPE.map((lt) => {
                return <option key={lt}>{lt}</option>;
              })}
            </Form.Control>
            {isInPerson && (
              <Form.Control
                value={oneDate.in_person_location}
                placeholder="In Person Location"
                style={{ flex: "300%" }}
                disabled={!isAdmin}
                onChange={(ev) => {
                  setDateField(idx, "in_person_location", ev.target.value);
                }}
                className="input-item"
              />
            )}
          </div>
        </div>
        <div className="bottom-rows">
          <div className="input-item">
            <span className="mr-2">Session Manager</span>
            <strong>
              {oneDate.managers
                .map((m) => `${m.first_name || ""} ${m.last_name || ""}`)
                .join(", ")}
            </strong>
            <div
              className="cursor-pointer ml-auto text-danger"
              onClick={() => {
                goToFreelancerInvite();
              }}
            >
              <PlusIcon />
            </div>
          </div>
          <div className="input-item">
            <span className="mr-2">Lobby Manager</span>
            <strong>
              {oneDate.lobbyManager.map(
                (m) => `${m.first_name || ""} ${m.last_name || ""}`
              )}
            </strong>
            <div
              className="cursor-pointer ml-auto text-danger"
              onClick={() => {
                goToFreelancerInvite();
              }}
            >
              <PlusIcon />
            </div>
          </div>
        </div>
        {isAdmin && (
          <div className="day-item-btn-container">
            <button
              className="h-14-600 danger-button"
              onClick={() => {
                _removeDate(idx);
              }}
            >
              Remove
            </button>
            <button
              className="primary-solid-button h-14-600 text-white"
              onClick={() => {
                onSubmit();
              }}
            >
              Save
            </button>
          </div>
        )}
        <span>{oneDate?.error?.date}</span>
      </div>
      <SendClientEmailModal
        show={!!oneDate && !!emailSessionLink}
        onHide={() => {
          setEmailSessionLink(null)
        }}
        studio={studio}
        session={session}
        emailSessionParams={oneDate}
        emailSessionLink={emailSessionLink}
      />
    </div>
  );
};
