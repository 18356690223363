import { api_host, token } from './consts'
import moment from "moment-timezone"

export const getSessionVideos = async (session_id) => {
  const resp = await fetch(`${api_host}/videos/${session_id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const getGroupVideos = async (group_id) => {
  const resp = await fetch(`${api_host}/videos/by-group/${group_id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const getArchivedSessionVideos = async (session_id) => {
  const resp = await fetch(`${api_host}/videos/archived/${session_id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const deleteVideo = async (video_id) => {
  const resp = await fetch(`${api_host}/videos/${video_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const updateVideo = async (video_id, fields) => {
  const resp = await fetch(`${api_host}/videos/${video_id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(fields)
  })
  return await resp.json()
}

export const updateManyVideo = async (video_ids, fields) => {
  const resp = await fetch(`${api_host}/videos/update-many`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      ids: video_ids,
      fields
    })
  })
  return await resp.json()
}

export const directUpload = async (
  file, meeting_id = '', additional = '',
  progressCallback = () => { }, percentIndicator = '.uploading-percent-indicator'
) => {
  const ext = file.name.split('.').pop()
  const upKey = `${meeting_id}_${moment.tz('Europe/London').format('YYYY-MM-DD-HH-mm-ss SSS')}${additional}.${ext}`
  const url = await fetch(`${api_host}/s3-put-url/${upKey}?mimeType=${file.type}`).then(r => r.json()).then(r => r.url)

  document.querySelectorAll(percentIndicator).forEach(elem => elem.innerText = ``)

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', url, true)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        document.querySelectorAll(percentIndicator).forEach(elem => elem.innerText = `${parseInt(percentComplete)}%`)
        progressCallback && progressCallback(percentComplete)
      }
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          // Request was successful, resolve with JSON response
          resolve({ Key: upKey });
        } else {
          // Request failed, reject with error message
          reject(new Error(`Request failed: ${xhr.status} ${xhr.statusText}`));
        }
      }
    };
    xhr.onerror = function () {
      reject(new Error('Network error occurred'));
    };
    xhr.send(file);
  })
}

export const addVideoEntry = async (key, group, session, thumbKey) => {
  const resp = await fetch(`${api_host}/videos/add-video-entry`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ key, group, session, thumbKey })
  })
  document.querySelectorAll(".uploading-percent-indicator").forEach(elem => elem.innerText = ``)
  return await resp.json()
}

export const uploadNewVideo = async (file, session_id, group, uploadThroughQueue = false, progressCallback = () => { }) => {
  // const formData = new FormData()
  // formData.append('file', file)
  // formData.append('session', session_id)
  // formData.append('group', group)
  // if (uploadThroughQueue) {
  //   formData.append('upload_through_queue', true)
  // }
  // const resp = await fetch(`${api_host}/videos/upload-video`, {
  //   method: 'POST',
  //   headers: {
  //     'Authorization': `Bearer ${token}`
  //   },
  //   body: formData
  // })
  // return await resp.json()

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('session', session_id);
    formData.append('group', group);

    if (uploadThroughQueue) {
      formData.append('upload_through_queue', true);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${api_host}/videos/upload-video`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        console.log('Upload Progress: ' + percentComplete + '%');
        progressCallback && progressCallback(percentComplete)
      }
    };
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          // Request was successful, resolve with JSON response
          const resp = JSON.parse(xhr.responseText);
          resolve(resp);
        } else {
          // Request failed, reject with error message
          reject(new Error(`Request failed: ${xhr.status} ${xhr.statusText}`));
        }
      }
    };
    xhr.onerror = function () {
      reject(new Error('Network error occurred'));
    };
    xhr.send(formData);
  });
}

export const createZipAndSendMail = async (video_ids, date, email, from_posting=false) => {
  const resp = await fetch(`${api_host}/videos/get-zip`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      filename: date,
      email,
      keys: video_ids,
      from_posting
    })
  })
  return await resp.text()
}

export const getLastVideosTime = async (ids) => {
  const resp = await fetch(`${api_host}/videos/last-videos`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ ids })
  })
  return await resp.json()
}

export const getManyByTalent = async (record_id) => {
  const resp = await fetch(`${api_host}/videos/many-by-record/${record_id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const updateVideoSort = async (data) => {
  const resp = await fetch(`${api_host}/videos/update-video-sort`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
  return await resp.json()
}

export const updateGroupOrderVideoReviewPage = async (groupIds) => {
  const resp = await fetch(`${api_host}/videos/update-group-sort-vrpage`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ groupIds })
  })
  return await resp.json()
}
export const updateVRVideoOrder = async (videoId) => {
  const resp = await fetch(`${api_host}/videos/update-review-video-order`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ videoId })
  })
  return await resp.json()
}

export const likeVideoById = async (videoId, isLiked, recordIds, groupId = null) => {
  const resp = await fetch(`${api_host}/videos/like-video/${videoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ isLiked, recordIds, groupId })
  })
  return await resp.json()
}

export const takeVideoThumbnail = async (key, timemark) => {
  const resp = await fetch(`${api_host}/videos/take-video-thumbnail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ key, timemark })
  })
  return await resp.json()
}

export const addCommentToVideo = async (videoId, comment) => {
  const resp = await fetch(`${api_host}/videos/add-session-comment/${videoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ comment })
  })
  return await resp.json()
}

export const getSessionCommentsByVideoId = async (videoId) => {
  const resp = await fetch(`${api_host}/videos/session-comments/${videoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const deleteSessionCommentById = async (videoId, commentId) => {
  const resp = await fetch(`${api_host}/videos/delete-session-comment/${videoId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ commentId })
  })
  return await resp.json()
}