import React, { useCallback, useContext, useEffect, useReducer, useState, useRef } from 'react'
import {Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone'
import { Modal, Button, Container, Row, Col } from 'react-bootstrap'
import { getUserById, getAllSessions, updateStudio, api_host } from '../../services'
import {ShowLoadingContext} from '../../Context'
import { useAuthedUser } from '../../hooks/auth'
import { SESSION_LOCATION_TYPE, USER_TYPE } from '../../constants'
import { SessionActionBts } from '../Studio/Session'
import _ from 'lodash'
import './style.scss'
import { useHistory } from 'react-router-dom'
import { FaCalendarPlus, FaCaretLeft } from 'react-icons/fa'
import Switch from "react-switch"

const initialState = [];

function reducer(state, action) {
  switch (action.type) {
    case 'add':
      const newEvents = action.payload.filter(itNew => state.findIndex(itOld=>itOld.id === itNew.id) === -1)
      if (newEvents.length > 0) {
        return [...state, ...newEvents];
      }
      return state
    case 'clear':
      return []
  }
}

const eventTypeColors = {
  'session-book': '#ee514f',
  'session-hold': '#25354d',
  'freelancer.available': '#28a745',
  'freelancer.unavailable': '#ee514f',
  'google-calendar-event': '#28a745',
  'google-calendar-eventCasting Bookings': '#25354d',
  'invoiced': '#28a745'
}


const CalendarView = () => {
  const {id} = useAuthedUser()
  const [events, dispatch] = useReducer(reducer, initialState)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const toggleLoadingState = useContext(ShowLoadingContext)
  const calendarRef = useRef(null)
  const [user, setUser] = useState(null)
  const [showInPerson, setShowInPerson] = useState(false)
  const history = useHistory()
  const rangeRef = useRef(null)

  const loadUser = useCallback(async ()=> {
    try {
      setUser(await getUserById(id))
    } catch (error) {
    }
  }, [id])  

  useEffect(() => {
    loadUser()
  }, [loadUser])

  useEffect(() => {
    const availableDates = _.get(user, 'freelancer_profile.available_dates', [])
    const nonAvailableDates = _.get(user, 'freelancer_profile.non_available_dates', [])
    const calendarAvailableDates = []
    const calendarNonAvailableDates = []
    availableDates.forEach(date => {
      calendarAvailableDates.push({
        id: `${date}`,
        start: new Date(date),
        end: new Date(date + 24 * 2600 * 1000),
        title: `Available`,
        type: 'freelancer.available',
        allDay: true,
      })
    })
    nonAvailableDates.forEach(date => {
      calendarNonAvailableDates.push({
        id: `${date}`,
        start: new Date(date),
        end: new Date(date + 24 * 2600 * 1000),
        title: `Unavailable`,
        type: 'freelancer.unavailable',
        allDay: true,
      })
    })
    dispatch({
      type: 'add',
      payload: calendarNonAvailableDates.concat(calendarAvailableDates)
    })
  }, [user])

  const loadSessions = async (dateRange) => {
    dispatch({ type: 'clear' })
    rangeRef.current = dateRange
    const res = await getAllSessions(dateRange)
    const eventsFromSessions = []
    res.forEach(it=>{
      it.dates.forEach(date => {
        const start = new Date(date.start_time)
        const end = new Date(date.start_time)
        end.setMinutes(end.getMinutes() + 30)
        eventsFromSessions.push({
          id: `session-date-${date._id}`,
          start,
          end,
          title: `${it.studio.name} (${date.book_status}) ${it.studio.invoiced ? 'Invoiced': ''}`,
          type: it.studio.invoiced ? 'invoiced':  `session-${date.book_status}`.toLowerCase(),
          allDay: true,
          meta: {
            type: 'timed-event',
            studio: it.studio,
            session: it,
            castingDirectors: it.studio.casting_directors,
            lobbyManager: date.lobbyManager,
            manager: date.managers,
            castingLocation: date.location_type,
            inPersonLocation: date.in_person_location,
            bookStatus: date.book_status,
            isInPerson: [SESSION_LOCATION_TYPE[1], SESSION_LOCATION_TYPE[2]].includes(date.location_type),
            htmlLink: `/?focused_session=${it._id}&studio=${it.studio._id}`
          }
        })
      })
    })
    dispatch({
      type: 'add',
      payload: eventsFromSessions
    })
  }

  const loadAllEvents = async (dateRange) => {
    toggleLoadingState(true)
    await Promise.all([loadSessions(dateRange)])
    toggleLoadingState(false)
  }

  const onDateRangeChange = (range) => {
    let dateRange = null
    if (Array.isArray(range)) {
      const endDate = range[range.length - 1]
      dateRange = {
        start: range[0],
        end: endDate
      }
    } else {
      dateRange = range
    }

    dateRange.start = dateRange.start.toISOString()
    dateRange.end = dateRange.end.toISOString()
    loadAllEvents(dateRange)
  }
  const eventStyleGetter = (event, start, end, isSelected) => {
    let bgColor = 'transparent'
    let textColor = '#000000'
    if (+new Date(start) > +new Date()) {
      bgColor = eventTypeColors[event.type]
      textColor = 'white'
      bgColor += 'DD'
    } else {
      textColor = eventTypeColors[event.type]
      bgColor = textColor + '22'
    }
    const isStriped = event.meta.castingLocation === SESSION_LOCATION_TYPE[1]
    const s=  {
      style: {
        color: textColor,
        background: isStriped ? `repeating-linear-gradient(
          45deg,
          ${bgColor},
          ${bgColor} 80%,
          ${bgColor.slice(0, -2)}77 80%,
          ${bgColor.slice(0, -2)}77 100%
        )` : bgColor
      }
    }
    console.log(s)
    return s
  }

  const localizer = momentLocalizer(moment)

  const onSelectEvent = (e) => {
    if (e.type.startsWith('freelancer')) { return }
    setSelectedEvent(e)
  }
  useEffect(() => {
    const dateRange = {}
    let a = new Date
    a.setDate(1)
    a.setDate(1 - a.getDay())
    dateRange.start = a.toISOString()
    a = new Date
    a.setDate(1)
    a.setMonth(a.getMonth() + 1)
    a.setDate(0)
    a.setDate(a.getDate() + 7 - a.getDay())
    dateRange.end = a.toISOString()
    loadAllEvents(dateRange)
  }, [])

  const updateProjectInvoiceStatus =  async (studioId, invoiced) => {
    toggleLoadingState(true)
    const formData = new FormData()
    formData.append('invoiced', !!invoiced)
    await updateStudio(formData, studioId)
    setSelectedEvent(null)
    await loadAllEvents(rangeRef.current)
    toggleLoadingState(false)
  }

const filteredEvents = events.filter(event => {
  return showInPerson || (!showInPerson && event.meta.castingLocation !== SESSION_LOCATION_TYPE[1] && event.meta.castingLocation !== SESSION_LOCATION_TYPE[3])
})


  return (
    <div className="pt-2 px-5 pb-5 page-content calendar-page">
      <h5 className='mb-3 text-danger cursor-pointer mr-auto d-inline-flex align-items-center h6' onClick={() => {
        history.push('/')
      }}>
        <FaCaretLeft />
        <span className="ml-1">Sessions</span>
      </h5>
      <label title="Sync Calendar" className='cursor-pointer mb-0 ml-5 text-danger' onClick={() => {
        window.open('webcal://' + (`${api_host}/sessions/ics/${user._id}`).split('://')[1] )
      }}>
        <FaCalendarPlus  size={18} />
      </label>
      <label className='cursor-pointer ml-5'>
        <input type='checkbox' className='mr-2' checked={showInPerson} onChange={ev => {
          setShowInPerson(ev.target.checked)
        }} />
        Show In Person Sessions
      </label>
      <div className='calendar-container'>
        <Calendar
          ref={calendarRef}
          popup
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          onRangeChange={onDateRangeChange}
          onSelectEvent={onSelectEvent}
          eventPropGetter={eventStyleGetter}
        />
        <div className='legend-wrap mt-2'>
          <div className='legend-item'>
            <div className='thumb' style={{ backgroundColor: eventTypeColors['session-book'] }} />
            <span>= Project Booked</span>
          </div>
          <div className='legend-item'>
            <div className='thumb' style={{ backgroundColor: eventTypeColors['session-hold'] }} />
            <span>= Project On Hold</span>
          </div>
          <div className='legend-item'>
            <div className='thumb' style={{ backgroundColor: eventTypeColors['invoiced'] }} />
            <span>= Project Invoiced</span>
          </div>
        </div>
      </div>
      <Modal
        show={!!selectedEvent}
        onHide={()=>{setSelectedEvent(null)}}
        className="calendar-modal"
      >
        {!!selectedEvent && (
          <>
            <Modal.Header closeButton>
              <div>
              <h4 className="my-0 h-24-400">{selectedEvent.title}</h4>
              <p className="my-0">
                {(()=>{
                  if (selectedEvent.type === 'google-calendar-event') {
                    return 'Event from google calendar'
                  } else if (selectedEvent.type === 'session') {
                    return 'Event from session'
                  }
                })()}
              </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Container
                className="text-10"
                fluid
              >
                <Row>
                  <Col>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <label className="h-12-600">Project</label>
                        <p className="h-14-400">{selectedEvent.meta.studio.name}</p>
                      </div>
                      {USER_TYPE.IS_SUPER_ADMIN() && (
                        <div className='mt-2 d-flex flex-column'>
                          <label className="h-12-600">Invoiced?</label>
                          <Switch
                            checkedIcon={null} uncheckedIcon={null}
                            onColor="#28a745"
                            height={20}
                            checked={selectedEvent.meta.studio.invoiced}
                            onChange={(state) => {
                              updateProjectInvoiceStatus(selectedEvent.meta.studio._id, state)
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <label className="h-12-600">Date</label>
                    <p className="h-14-400">{moment(selectedEvent.start).format('MM/DD/YYYY hh:mm A')}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label className='h-12-600'>Casting Director</label>
                    <p className="h-14-400">
                      {selectedEvent.meta.castingDirectors && selectedEvent.meta.castingDirectors.length > 0
                        ? selectedEvent.meta.castingDirectors.map(it=>(
                          <p>{it.email}</p>
                        )) : (
                          <p>No Casting Directors found</p>
                        )
                      }
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label className="h-12-600">Lobby Manager</label>
                    {selectedEvent.meta.lobbyManager && selectedEvent.meta.lobbyManager.length > 0
                      ? selectedEvent.meta.lobbyManager.map(it=>(
                        <p className="h-14-400">{it.email}</p>
                      )) : (
                        <p className="h-14-400">No lobby managers found</p>
                      )
                    }
                  </Col>
                  <Col>
                    <label className="h-12-600">Session Manager</label>
                    {selectedEvent.meta.manager && selectedEvent.meta.manager.length > 0
                      ? selectedEvent.meta.manager.map(it=>(
                        <p className="h-14-400">{it.email}</p>
                      )): (
                        <p className="h-14-400">No session managers found.</p>
                      )
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="h-12-600">Book Status</label>
                    <p className="h-14-400">{selectedEvent.meta.bookStatus}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="h-12-600">Casting Location</label>
                    <p className="h-14-400">{selectedEvent.meta.castingLocation || '-'}</p>
                  </Col>
                  {selectedEvent.meta.isInPerson && (
                    <Col>
                      <label className="h-12-600">In Person Location</label>
                      <p className="h-14-400">{selectedEvent.meta.inPersonLocation}</p>
                    </Col>
                  )}
                </Row>
                <p className="h-14-600">
                  Click&nbsp;
                  <a
                    className="break-word text-danger "
                    href={selectedEvent.meta.htmlLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  &nbsp;to see the event detail
                </p>
                <SessionActionBts session={selectedEvent.meta.session} studio={selectedEvent.meta.studio} showInvoiceBtn={true} styling='flex-wrap'/>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                variant="primary"
                onClick={()=>{setSelectedEvent(null)}}
                className="primary-solid-button h-14-600 text-white"
              >
                OK
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  )
}
export default CalendarView
