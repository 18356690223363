import React, { useEffect, useState } from "react";
import { FaPaperPlane, FaSpinner } from "react-icons/fa";
import { listComments, saveComment } from "../../services/feedback";

const InlineComments = ({
  refId,
  pageId,
  record,
  disableFeedbackCommentsEdit = false,
  user,
  sessionComments=false
}) => {

  const [comments, setComments] = useState([])
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)

  const loadData = async()=> {
    const c = await listComments({ ref_id: refId, to_user: record._id, page_id: pageId })
    setComments(c)
  }

  useEffect(() => {
    if(sessionComments){
      setComments(record.comments)
    }else{
      loadData()
    }
  }, [refId, record, pageId])
  
  const addNewComment = async() => {
    try{
      setLoading(true)
      await saveComment({
        from_user: user.id,
        to_user: record._id,
        content,
        ref_id: refId,
        page_id: pageId
      })
      loadData()
      setContent('')
    }catch(e){
      console.log('error commenting:>', e)
    }finally{
      setLoading(false)
    }
  }

  return (
    <div
      onClick={(ev) => {
        ev.stopPropagation();
      }}
      className="d-flex flex-column"
    >

      {comments.map((comment, idx) => (
        <div className="mb-1" key={idx}>
          <label className="h-12-600 mb-0"> - {sessionComments ? comment.by?.email : comment.from_user?.email} - </label>
          <p className="mb-0">{comment.content}</p>
        </div>
      ))}
      {comments.length === 0 && <div>No comments.</div>}
      {!disableFeedbackCommentsEdit && 
        <div className="d-flex align-items-center mb-4">
          <textarea
            key="textarea"
            className="form-control"
            rows={1}
            value={content}
            onChange={(ev) => setContent(ev.target.value)}
            placeholder="New comment here"
          ></textarea>
          {
            loading ? <FaSpinner size='16px' className='spinning ml-2' /> : 
            <button
              key="submit"
              className="btn btn-text btn-sm ml-auto"
              onClick={() => addNewComment()}
              disabled={!content}
              title="Send"
            >
              <FaPaperPlane />
            </button>
          }
          
        </div>
      }
    </div>
  );
};

export default InlineComments;
