import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import SessionPersonCard from '../PostingPage/PersonCard'
import Switch from "react-switch"
import {
  static_root,
  updateSessionFeedbacks,
  fetchCheckInList,
  getCurrentGroup,
  getUser,
  twrFetchCheckInList,
  getcurrentTWRGroup,
  twrGetHeyjoeSessionRecords,
  twrGetOneHeyjoeRecord,
  twrGetTWRByDomain,
  twrGetOneRecord,
  twrGetStudioByTWRUri,
} from '../../services'
import Footer from '../../components/Footer'
import { POSTINGPAGE_PERMISSIONS } from '../../constants'
import RecordVideosModal from './RecordVideos'
import './sizecards.scss'
import { CautionIconSm, EditIconDark, PrinterIcon } from '../../assets/icons/session'
const interval = 5000 // query api every 30 seconds

const itemWidth = 250

const fileFields = [
  'size_card_pdf',
  'schedule_pdf',
  'script_pdf',
  'storyboard_pdf',
  'treatment_pdf'
]

const SizeCards = ({
  studio,
  session,
  isClient = true,
  candidates,

  listTab,
  setListTab,
  setTwrGroupCandidates,
  setTwrCandidates,
  roles,

  dates,
  selectedDate,
  setSelectedDate
 }) => {
  const [user, setUser] = useState(null)
  const [ filter, setFilter ] = useState('all')
  const [ feedbackUsers, setFeedbackUsers] = useState([])
  const [ userFilter, setuserFilter ] = useState('all')
  const [ pickPrivate, setPickPrivate ] = useState(false)
  const [ yesPickShow, setYesPickShow ] = useState(false)
  const [ twrStudio, setTwrStudio ] = useState(null)
  const [ roleFilter, setRoleFilter ] = useState('all')
  const [ videoRecord, setVideoRecord ] = useState(null)
  const [ showCommentInline, setShowCommentInline ] = useState(false)
  const [sessionDateData, setSessionDateData] = useState(null)
  const [testMode, setTestMode] = useState(false)
  const [likedFilter, setLikedFilter] = useState(false)
  const [likedVideosFilter, setLikedVideosFilter] = useState(false)
  const [countPerRow, setCountPerRow] = useState(5)
  const [activeRidx, setActiveRidx] =useState(null)

  const setCount = () => {
    setCountPerRow(Math.max(1, parseInt((document.documentElement.clientWidth - 350) / (itemWidth + 32))))
  } 
  const rowWidth = countPerRow * (itemWidth + 32)

  useEffect(() => {
    setCount()
    window.addEventListener('resize', setCount)
  },[])

  const fetchTWRStudio = async () => {
    const { twr } = session
    const parsed = twr.match(/(\w+)\/(\w+)/)
    if (parsed) {
      const twrDomain = parsed[1]
      const twrStudioUri = parsed[2]
      const room = await twrGetTWRByDomain(twrDomain)
      const result = await twrGetStudioByTWRUri(room._id, twrStudioUri)
      setTwrStudio(result._id)
    }
  }

  const fetchTWRCandidates = async () => {
    if (!twrStudio) { return }
    const { twr_sync } = session
    let candidates = await twrFetchCheckInList(twrStudio)
    const twrCids = candidates.map(c => c._id)
    const currentGroup = await getcurrentTWRGroup(session._id) || {}
    if (twr_sync) {
      const tHCandidates = await Promise.all(candidates.map(async c => {
        return await twrGetOneHeyjoeRecord(c._id, session._id)
      }))
      const hCs = await twrGetHeyjoeSessionRecords(session._id)
      const hcIds = tHCandidates.map(h => h._id)
      const heyjoeCs = tHCandidates.concat(hCs.filter(hc => !hcIds.includes(hc._id)).map(c => ({ ...c, twr_deleted: true })))
      candidates = candidates.map(c => {
        const hc = heyjoeCs.find(h => h.twr_id === c._id)
        return {
          ...c,
          ...hc,
          _id: c._id
        }
      })
      const deletedTwrCandidates = await Promise.all(heyjoeCs.filter(h => !twrCids.includes(h.twr_id))
        .map(async h => {
          const c = await twrGetOneRecord(h.twr_id)
          return { ...c, ...h, _id: h.twr_id, twr_deleted: true }
        }))
      candidates = candidates.concat(deletedTwrCandidates)
    } else {
      const hCs = await twrGetHeyjoeSessionRecords(session._id)
      candidates = await Promise.all(hCs.map(async hc => {
        let c = candidates.find(c => c._id === hc.twr_id)
        let twr_deleted = false
        if (!c) {
          c = await twrGetOneRecord(hc.twr_id)
          twr_deleted = true
        }
        return {
          ...hc,
          ...c,
          _id: hc.twr_id,
          twr_deleted
        }
      }))
    }
    candidates = candidates.sort((c1, c2) => c1.twr_id.localeCompare(c2.twr_id))
      .map((c, idx) => ({ ...c, number: idx + 1}))
    const currentGroupRecords = (currentGroup.twr_records || []).map(r_id => {
      return candidates.find(c => c._id === r_id)
    }).filter(r => !!r)
    setTwrCandidates(candidates)
    setTwrGroupCandidates(currentGroupRecords)
  }

  useEffect(() => {
    if (session.twr) {
      fetchTWRStudio()
    }
    let queryParams = {}
    try {
      queryParams = JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    } catch(err) {
      queryParams = {}
    }
    setTestMode(!!queryParams.test)
  }, [session.twr])

  useEffect(() => {
    setUser(getUser())
    const softRefresh = () => {
      fetchTWRCandidates()
    }
    document.body.addEventListener('soft-refresh', softRefresh)
    return () => {
      document.body.removeEventListener('soft-refresh', softRefresh)
    }
  }, [])

  useEffect(() => {
    if (twrStudio && isClient) {
      const handle = setInterval(() => {
        fetchTWRCandidates()
      }, interval)
      return () => {
        clearInterval(handle)
      }
    }
  }, [twrStudio, isClient])

  useEffect(() => {
    const fbkUsers = []
    const rs = []
    candidates.map(c => {
      if (!rs.includes(c.role)) {
        rs.push(c.role)
      }
      return Object.keys(c.feedbacks || {})
    }).forEach(fs => {
      fs.forEach(f => {
        if (!fbkUsers.includes(f)) {
          fbkUsers.push(f)
        }
      })
    })
    setFeedbackUsers(fbkUsers)
  }, [candidates])

  useEffect(() => {
    if (user && session) {
      const myPrivacy = (session.feedbackPrivates || {})[user.id]
      switch(myPrivacy) {
        case 'full-private':
          setPickPrivate(true)
          break
        case 'yes-private':
          setPickPrivate(true)
          setYesPickShow(true)
          break
      }
      const dateData = session.dates.find(date => {
        return new Date().toDateString() === new Date(date.start_time).toDateString()
      })
      setSessionDateData(dateData)
    }
  }, [session, user])

  useEffect(() => {
    let restrict = ''
    if (pickPrivate && yesPickShow) { restrict = 'yes-private' }
    if (pickPrivate && !yesPickShow) { restrict = 'full-private' }
    if (!pickPrivate && !yesPickShow) { restrict = null }
    updateSessionFeedbacks(session._id, restrict)
  }, [pickPrivate, yesPickShow])

  const showRecordVideosModal = (record) => {
    setVideoRecord(record)
  }

  const filteredCandidates = candidates.filter(c => {
    let userFeedback = ''
    if (userFilter === 'all') {
      userFeedback = Object.values(c.feedbacks || {})
    } else {
      userFeedback = [(c.feedbacks || {})[userFilter]]
    }
    return (filter === "all" || userFeedback.includes(filter))
      && (roleFilter === 'all' || c.role.trim() === roleFilter.trim()) && (likedFilter ? c.hasLikedVideos : true)
  })
  let rows = []
  for(let i =0; i < filteredCandidates.length; i += countPerRow ){
    rows.push(filteredCandidates.slice(i, i + countPerRow))
  }
  const { twr_sync } = session

  return (
    <div>
      <div className="mt-3 pl-3 no-print">
            {dates && dates.length > 1 && (
              <div className="mr-auto">
                <div className="d-flex px-2 mb-2 align-items-center">
                  <label className="mb-0">Select Date</label>
                  <div className="ml-2">
                    <select value={selectedDate} onChange={ev => {
                      setSelectedDate(ev.target.value)
                    }} className="form-control form-control-sm">
                      <option value={""}>All Dates</option>
                      {dates.map(d => {
                        return (
                          <option key={d} value={d} > {d} </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            )}
        <div className='d-flex align-items-center justify-content-between'>
          <div className="d-flex align-items-end mr-auto">
            <div className="mr-2">
              <button
                className="action-button h-14-600"
                onClick={() => {
                  window.print()
                }}
              >
                <PrinterIcon />
                Print
              </button>
            </div>
            <div className="mr-2">
              <select
                key={roleFilter}
                className="form-control action-button h-14-600"
                value={roleFilter}
                onChange={ev => {
                  setRoleFilter(ev.target.value)
                }}
              >
                <option key="all" value="all">
                  All Roles
                </option>
                {roles.map(role => (
                  <option key={role}>
                    {role || '--no role--'}
                  </option>
                ))}
              </select>
            </div>
            <div className="mr-2">
              <select
                key={userFilter}
                className="form-control action-button h-14-600"
                value={userFilter}
                onChange={ev => {
                  setuserFilter(ev.target.value)
                }}
              >
                <option key="all" value="all">
                  All Users
                </option>
                {feedbackUsers.map(fu => (
                  <option key={fu}>
                    {fu}
                  </option>
                ))}
              </select>
            </div>
            <div className="mr-2">
              <select
                className="form-control action-button h-14-600"
                value={filter}
                onChange={ev => {
                  setFilter(ev.target.value)
                }}
              >
                <option key="all" value="all">All Feedback</option>
                <option key="yes" value="yes">Yes</option>
                <option key="no" value="no">No</option>
                <option key="maybe" value="maybe">Maybe</option>
              </select>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div className="d-flex align-items-center px-2">
              <input 
                id='private-picks'
                type='checkbox' 
                checked={pickPrivate}
                onChange={(e) => {
                  console.log('state :>> ', e.target.checked);
                  setPickPrivate(e.target.checked)
                  if (!e.target.checked) {
                    setYesPickShow(false)
                  }
                }} 
              />
              <label className='h-14-600 mb-0 ml-2' htmlFor='private-picks'>Make picks private</label>
            </div>
            <div class="dropdown">
              <button 
                class="action-button h-14-600 dropdown-toggle" 
                type="button" id="dropdownMenuButton" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
                >
                <EditIconDark/> Customize
              </button>
              <div class="dropdown-menu dropdown-menu-right pb-0 rounded-lg customize-drop-menu" aria-labelledby="dropdownMenuButton">
                  <div className='d-flex align-items-center p-2' onClick={ e =>  e.stopPropagation()}>
                    <input
                      id='show-comment-inline'
                      type='checkbox'
                      checked={showCommentInline}
                      onChange={(e) => {
                        setShowCommentInline(e.target.checked);
                      }}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='show-comment-inline'>Show Comments Inline</label>
                  </div>
                  <div className="d-flex align-items-center p-2">
                    <input
                      id='talent-with-liked-videos'
                      type='checkbox'
                      checked={likedFilter}
                      onChange={(e) => setLikedFilter(e.target.checked)}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='talent-with-liked-videos'>Show Only Talent With Liked Videos</label>
                  </div>
                  <div className="d-flex align-items-center p-2">
                    <input
                      id='liked-videos'
                      type='checkbox'
                      checked={likedVideosFilter}
                      onChange={(e) => setLikedVideosFilter(e.target.checked)}
                      disabled={!likedFilter}
                    />
                    <label className='h-14-400 mb-0 ml-2' htmlFor='liked-videos'>Show Only Liked Videos</label>
                  </div>
                  <div className="p-2 yes-picks-container">
                    <div className='d-flex align-items-center'>
                      <input
                        id='yes-picks'
                        type='checkbox'
                        checked={yesPickShow}
                        onChange={(e) => setYesPickShow(e.target.checked)}
                        disabled={!pickPrivate}
                      />
                      <label className='h-14-400 mb-0 ml-2' htmlFor='yes-picks'>Show Only Yes Picks</label>
                    </div>
                    {!pickPrivate && <div className='disabled-container mt-2' >
                      <div className='d-flex align-items-center'>
                        <CautionIconSm />
                        <span className='ml-2 h-14-600'>Disabled</span>
                      </div>
                      <span className='h-14-400'>Make Picks Private to show yes picks.</span>
                    </div>}
                  </div>
              </div>
            </div>
          </div>
        </div>
       
        {isClient &&
          <div className="ml-auto mr-2">
            <div className="d-flex align-items-center">
              {session.twr && <div className="tab-header d-flex">
                <label className={classnames("btn btn-sm flex-fill mb-0", { 'btn-danger': listTab === 'heyjoe' })} onClick={() => {
                  setListTab('heyjoe')
                }}>Hey Joe</label>
                <label className={classnames("btn btn-sm flex-fill mb-0", { 'btn-danger': listTab === 'twr' })} onClick={() => {
                  setListTab('twr')
                }}>TWR</label>
              </div>}
            </div>
            {listTab === 'twr' && <div className="text-right">
              <span>
                {twr_sync ? 'Syncing': 'Not Syncing'}
              </span>
            </div>}
          </div>
        }
      </div>
      <div className="size-cards mt-2">
        {rows.map((row,ridx) => {
          return (
            [
              <div className="video-row" key={ridx} style={{width: `${rowWidth}px`}}>
                {row.map(c => {
                  return (
                    <div key={c._id} className="person-card avoid-break">
                      <SessionPersonCard
                        key={c._id}
                        {...c}
                        topAvatar={true}
                        studio={studio}
                        showNumber={true}
                        useSelfData={false}
                        session_id={session._id}
                        hideContact={!POSTINGPAGE_PERMISSIONS.CAN_VIEW_CONTACT()}
                        showRecordVideosModal={showRecordVideosModal}
                        showCommentInline={showCommentInline}
                        setActiveRidx={setActiveRidx}
                        ridx={ridx}
                        videoRecord={videoRecord}
                      />
                    </div>
                  )
                })}
              </div>,
              activeRidx === ridx && videoRecord ? 
                <RecordVideosModal
                  key={videoRecord && videoRecord._id}
                  record={videoRecord} 
                  likedVideosFilter={likedVideosFilter}
                  candidates={candidates}
                  setVideoRecord={showRecordVideosModal}
                /> : null
            ]
          )
        })}

        {filteredCandidates.length === 0 && (
          <div className="text-center w-100">
            No candidates
          </div>
        )}

        <div className="gap-filler"></div>
      </div>
      <Footer force={true} />
    </div>
  )
}

export default SizeCards
