import React, { useEffect, useState } from 'react'
import { deleteComments, listComments, listFeedbacks, saveComment, saveFeedback } from '../../services/feedback'
import YesIcon from '../../components/icons/yes'
import NoIcon from '../../components/icons/no'
import MaybeIcon from '../../components/icons/maybe'
import ReshootIcon from '../../components/icons/reshoot'
import { FaComment, FaImage, FaMinus, FaSpinner } from 'react-icons/fa'
import { formatHour } from '../../utils'
import { getUser } from '../../services'
import { Button, Modal } from 'react-bootstrap'
import ThumbImage from '../../components/ThumbImage'
import './personcard.scss'
import { joinRoom, leaveRoom } from '../../ws'
import TalentInfoModal from '../Session/components/TalentInfoModal/TalentInfoModal'
import Avatar from "react-avatar";
import { SmallTrashIcon } from '../../assets/icons/session'

const user = getUser()

const fbsArray = [{
  value: 'yes',
  icon: YesIcon
}, {
  value: 'no',
  icon: NoIcon
}, {
  value: 'maybe',
  icon: MaybeIcon
}, {
  value: 'reshoot',
  icon: ReshootIcon
}]

export const FbkC = ({
  refId,
  pageId,
  toUser,
  showPrivate,
  showInlineComments = false,
  allowIndividualFbks = false,
  readOnly = false,
  showFeedBack = true,
  commentsTitle = "Comments",
  showVideoReviewComments = false,
  showSessionComments = false,
  record=null
}) => {
  const [loading, setLoading] = useState(false)
  const [fbks, setFbks] = useState([])
  const [cmts, setCmts] = useState([])
  const [commentsVisible, setCommentsVisible] = useState(false)
  const [content, setContent] = useState('')
  const [commentId, setCommentId] = useState('')
  const loadData = async () => {
    setLoading(true)
    try {
      const f = await listFeedbacks({ ref_id: refId, to_user: toUser, page_id: pageId })
      const c = await listComments({ ref_id: refId, to_user: toUser, page_id: pageId })
      setFbks(f.filter(f => allowIndividualFbks || !f.from_user))
      setCmts(c)
    } catch (err) {
      console.log('Fbkc load failed', err)
    }
    setLoading(false)
  }

  const addNewComment = async () => {
    if (readOnly) { return }
    setLoading(true)
    setContent('')
    await saveComment({
      from_user: user.id,
      to_user: toUser,
      content,
      ref_id: refId,
      page_id: pageId
    })
    await loadData()
  }

  const setMyFeedback = async (v) => {
    if (readOnly) { return }
    setLoading(true)
    await saveFeedback({
      ref_id: refId,
      page_id: pageId,
      from_user: allowIndividualFbks ? user.id : null, // For videos we just set from_use to empty so that feedbacks is shared user.id,
      to_user: toUser,
      feedback: v
    })
    await loadData()
  }

  useEffect(() => {
    if(showSessionComments && record) {
      setCmts(record.comments)
    }
    else{
      loadData()
  
      joinRoom(`feedback-ref-${refId}-${pageId}`)
      joinRoom(`comment-ref-${refId}-${pageId}`)
      const wsMessageHandler = (event) => {
        try {
          const ev = event.detail
          switch (ev.type) {
            case 'feedback-ref':
            case 'comment-ref':
              loadData()
          }
        } catch (err) { }
      }
      document.body.addEventListener('ws-message', wsMessageHandler)
      return () => {
        leaveRoom(`feedback-ref-${refId}-${pageId}`)
        leaveRoom(`comment-ref-${refId}-${pageId}`)
        document.body.removeEventListener('ws-message', wsMessageHandler)
      }
    }

  }, [refId, toUser, pageId])

  let fbk = fbks[0]?.feedback
  let myF = fbks.find(f => f.from_user?._id === user.id)?.feedback

  const onConfirmDelete = async() => {
    await deleteComments(commentId)
    setCommentId('')
    loadData()
  }

  return (
    <div className='feedback-container'>
      {showFeedBack && (
        <div className='d-flex align-items-center'>
          <div className='feedback-wrapper d-flex align-items-center flex-wrap'>
            {fbsArray.map(item => {
              const fv = item.value
              const FbIcon = item.icon
              const fs = fbks.filter(f => f.from_user && f.feedback === fv).map(f => f.from_user.email)
              return (
                <div key={fv} className={"fb-item " + ((allowIndividualFbks ? myF === fv : fbk === fv) ? 'active' : '')} onClick={(ev) => {
                  ev.stopPropagation()
                  setMyFeedback(fv)
                }} title={fs.join('\n')}>
                  <FbIcon />
                  <span className='h-12-600 text-span'>{item.value}</span>
                  <span>{allowIndividualFbks ? fs.length : null}</span>
                </div>
              )
            })}
            {fbk && (
              <div
                key="remove-feedback"
                className="ml-2 cursor-pointer"
                title="Remove my feedback"
                onClick={(ev) => {
                  ev.stopPropagation()
                  setMyFeedback(null)
                }}
              >
                <FaMinus />
              </div>
            )}
          </div>
          <div className='ml-5 cursor-pointer' onClick={() => {
            setCommentsVisible(true)
          }}>
            <FaComment />
            <span className='ml-1'>{cmts.length}</span>
          </div>
          {loading && (
            <span className='spinning ml-2'>
              <FaSpinner />
            </span>
          )}
        </div>
      )}
      {showInlineComments
        ? (
          <div className={`${(showVideoReviewComments || showSessionComments) ? 'video-review-comments' : 'comments-wrapper'}`}>
            {!(showVideoReviewComments || showSessionComments) ? <hr /> : null}
            <h5 className="h-14-700 primary-color mb-4">{commentsTitle}</h5>
            <div className={'note-container comments-list cl-' + refId}>
              {cmts.map((comment, idx) => (
                <div key={idx} className="note-item d-flex aling-items-center p-1">
                  <div className="left">
                    <Avatar
                      className="participantAvatar"
                      name={ 
                        showSessionComments ? comment.by.first_name + " " + comment.by.last_name : comment?.from_user ? comment?.from_user?.first_name + " " + comment?.from_user?.last_name : "Deleted User"
                      }
                      size="32px"
                      round="32px"
                      textSizeRatio={2}
                      // @ts-ignore
                      title={false}
                    />
                  </div>
                  <div className="ml-2 right">
                    <div className="comment-author d-flex align-items-center ">
                      <span className="h-12-600">
                        {showSessionComments ? `${comment.by.first_name} ${comment.by.last_name}` : `${comment?.from_user ? comment?.from_user?.first_name + " " + comment?.from_user?.last_name : "Deleted User"}`}
                      </span>
                          
                    </div>
                    <span className="h-14-400">{comment.content}</span>
                  </div>
                  {(user.id === comment.from_user?._id && !readOnly) && (
                    <button
                      title='Remove Comment'
                      className='btn primary-btn trash-btn'
                      onClick={() => setCommentId(comment._id)}
                      disabled={user.id !== comment.from_user?._id}> 
                      <SmallTrashIcon />
                    </button>
                  )}
                </div>
              ))}
              {cmts.length === 0 && (
                <span className='h-12-600'>
                  No comments yet.
                </span>
              )}
            </div>
            {!readOnly && (
              <div className='d-flex flex-column mt-3'>
                <textarea
                  placeholder="Add your comment"
                  rows="4"
                  cols="50"
                  className="comment-textarea br-8 h-14-400 p-2 form-control"
                  value={content}
                  onChange={ev => setContent(ev.target.value)}
                ></textarea>
                <button
                  className="h-14-700 primary-outline-button my-2 ml-auto text-primary"
                  onClick={addNewComment}
                  disabled={!content}
                >
                  Comment
                </button>
              </div>
            )}
            <Modal
              show={commentId}
              onHide={() => { setCommentId('') }}
            >
              <Modal.Header>
                <h4>Want to delete comment?</h4>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  className="btn-w-md"
                  variant="danger"
                  onClick={onConfirmDelete}
                >
                  Yes
                </Button>
                <Button
                  variant="light"
                  className="btn-w-md"
                  onClick={() => { setCommentId('') }}
                >
                  Cancel
                </Button>
              </Modal.Footer>

            </Modal>
          </div>
        ) : (
          <Modal
            key="comment-modal"
            show={commentsVisible}
            onHide={() => setCommentsVisible(false)}
          >
            <Modal.Header closeButton>
              <h5>Comments</h5>
            </Modal.Header>
            <Modal.Body>
              <div className={'comments-list cl-' + refId}>
                {cmts.map((comment, idx) => (
                  <div key={idx}>
                    <label>{comment.from_user.email}</label>
                    <p>{comment.content}</p>
                  </div>
                ))}
                {cmts.length === 0 && (
                  <div>
                    No comments yet.
                  </div>
                )}
              </div>
            </Modal.Body>
            {!readOnly && (
              <Modal.Footer>
                <textarea
                  className="form-control"
                  value={content}
                  onChange={ev => setContent(ev.target.value)}
                ></textarea>
                <button
                  className="btn btn-danger"
                  onClick={addNewComment}
                  disabled={!content}
                >
                  Comment
                </button>
              </Modal.Footer>)}
          </Modal>
        )}
    </div>
  )
}

const PersonInfo = ({
  record,
  hideContact = false,
}) => {
  const [showContact, setShowContact] = useState(false)
  const [showTalentInfoModal, setShowTalentInfoModal] = useState(false);

  return (
    <div className='person-info d-flex'>
      <div>
        <div className='d-flex flex-column'>
          <span className='h-16-600'>{record.first_name} {record.last_name}</span>
        </div>
        {!hideContact && ( 
          <>
            <div className='d-flex flex-wrap'>
              <div className='d-flex flex-column mt-2 mr-3'>
                <span className='h-12-600'>Agent</span>
                <span className='h-14-400'>{record.agent ? record.agent : '-'}</span>
              </div>
              <div className='d-flex flex-column mt-2'>
                <span className='h-12-600'>Agent Phone</span>
                <span className='h-14-400'>{record.agent_phone ? record.agent_phone : '-'}</span>
              </div>

            </div>
            <div className='d-flex flex-column mt-2'>
              <span className='h-12-600'>Role</span>
              <span className='h-14-400'>{record.role ? record.role : '-'}</span>
            </div>
          </>
        )}
        <div className="my-2">
          <label className={hideContact ? "d-none" : "contact-button mb-0 h-12-600 cursor-pointer"} onClick={(ev) => {
            ev.stopPropagation()
            setShowContact(!showContact)
          }}>{showContact ? 'Hide' : 'Show'}&nbsp;Contact</label>
          {showContact &&
            <div className='ml-2'>
              <p className="card-text mb-0">
                <span className='h-12-600'>Phone:</span>
                <span className='h-14-400 ml-2'>{record.phone}</span>
              </p>
              <p className="card-text mb-0">
                <span className='h-12-600'>Email:</span>
                <span className='h-14-400 ml-2'>{record.email}</span>
              </p>
            </div>}
        </div>
      </div>
      <div className='d-flex flex-column align-items-end'>
        <button
          className="btn btn-link p-0"
          onClick={() => setShowTalentInfoModal(true)}>
          <ThumbImage
            src={record.avatar}
            className="small-avatar"
          />
        </button>
      </div>
      <TalentInfoModal
        showTalentInfoModal={showTalentInfoModal}
        setShowTalentInfoModal={setShowTalentInfoModal}
        {...record}
        logo={record.avatar}
        hideContact={hideContact}
        hideCommentSection={true}
        hideFeedbackComments={true}
      />
    </div>
  )
}

const PersonCardV = ({
  record,
  hideContact,
  refId,
  pageId,
  showPrivate,
  readOnly,
  showInlineComments,
  allowIndividualFbks,
  videoReviewGroupId = '',
  showVideoReviewComments = false,
  showSessionComments = false
}) => {
  return (
    <div className='person-card-v'>
      <PersonInfo
        record={record}
        hideContact={hideContact}
      />
      {showSessionComments && (
        <div className='mb-2 w-100'>
          <FbkC
            refId={videoReviewGroupId}
            pageId={record.session}
            toUser={record._id}
            showPrivate={showPrivate}
            readOnly={true}
            showFeedBack={false}
            showInlineComments={true}
            commentsTitle="Session Comments"
            showSessionComments={showSessionComments}
            record={record}
          />
        </div>
      )}
      {showVideoReviewComments && videoReviewGroupId && (
        <div className='mb-2 w-100'>
          <FbkC
            refId={videoReviewGroupId}
            pageId={record.session}
            toUser={record._id}
            showPrivate={showPrivate}
            readOnly={true}
            showFeedBack={false}
            showInlineComments={true}
            commentsTitle="Casting Director Comments"
            showVideoReviewComments={showVideoReviewComments}
          />
          <hr/>
        </div>
      )}
      <FbkC
        refId={refId}
        pageId={pageId}
        toUser={record._id}
        showPrivate={showPrivate}
        readOnly={readOnly}
        showInlineComments={showInlineComments}
        allowIndividualFbks={allowIndividualFbks}
      />
    </div>
  )
}

export default PersonCardV
