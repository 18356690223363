import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { FaComment, FaPlayCircle, FaPaperPlane, FaMinus } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import YesIcon from '../../components/icons/yes'
import NoIcon from '../../components/icons/no'
import MaybeIcon from '../../components/icons/maybe'
import ThumbImage from '../../components/ThumbImage'
import { 
  setFeedback,
  getUser,
  newComment,
  twrSetFeedback,
  twrNewComment
} from '../../services'
import { POSTINGPAGE_PERMISSIONS, USER_TYPE } from '../../constants'
import { formatHour } from '../../utils'
import TalentInfoModal from '../Session/components/TalentInfoModal/TalentInfoModal'
import './personcard.scss'
import { CloseIconBlack } from '../../assets/icons/session'

const user = getUser()


const SessionPersonCard = ({
  studio,
  _id,
  first_name,
  last_name,
  email,
  phone,
  skipped,
  avatar,
  topAvatar,
  role,
  agent,
  hideContact = true,
  showNumber = false,
  number = 0,
  feedbacks,
  comments,
  commentRelateClick,
  session_id,
  twr_id,
  actual_call,
  twr_deleted,
  groups,
  showRecordVideosModal,
  hideFeedbackComments = false,
  disableFeedbackCommentsEdit = false,
  showCommentInline = false,
  photos,
  agent_email,
  height, 
  agent_phone,
  instagram_handle,
  tiktok_handle,
  clothes_size_object,
  clothes_type,
  setActiveRidx,
  ridx,
  videoRecord
}) => {
  const [showContact, setShowContact] = useState(false)
  const [commentsVisible, setCommentsVisible] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [content, setContent] = useState('')
  
  const fbks = (feedbacks || {})
  const cmts = (comments || [])

  const myFeedback = fbks[user.email] || fbks[user.id]

  const setMyFeedback = async (feedback) => {
    if (disableFeedbackCommentsEdit) { return }
    if(!!twr_id) { await twrSetFeedback(_id, feedback) } else { await setFeedback(_id, feedback) }
  }

  const addNewComment = async () => {
    if (disableFeedbackCommentsEdit) { return }
    if(!!twr_id) { await twrNewComment(_id, content) } else {  await newComment(_id, content) }
    setContent('')
  }

  const activeClass = (feedback) => {
    if (myFeedback === feedback) {
      return 'active'
    }
  }

  let MyFeedbackIcon = null

  if (POSTINGPAGE_PERMISSIONS.CAN_LEAVE_FEEDBACK()) {
    switch(myFeedback) {
      case 'yes':
        MyFeedbackIcon = <YesIcon />
        break
      case 'no':
        MyFeedbackIcon = <NoIcon />
        break
      case 'maybe':
        MyFeedbackIcon = <MaybeIcon />
        break
    }
  }

  let feedbackCounts = {
    yes: 0,
    no: 0,
    maybe: 0
  }
  let feedbackUsers = {
    'yes': [],
    'no': [],
    'maybe': []
  }
  Object.keys(fbks).forEach(email => {
    const v = fbks[email]
    if (v) {
      feedbackUsers[v] = feedbackUsers[v].concat(email)
    }
  })

  Object.values(fbks).forEach(value => {
    if (isNaN(feedbackCounts[value])) {
      return
    }
    feedbackCounts[value] ++
  })

  const feedbackBar = [
    <div key="yes" className={"feedback-item " + activeClass('yes')} onClick={(ev) => {
      ev.stopPropagation()
      setMyFeedback('yes')
    }} title={feedbackUsers['yes'].join('\n')}>
      <YesIcon />
      <span>{feedbackCounts['yes']}</span>
    </div>,
    <div key="no" className={"feedback-item " + activeClass('no')} onClick={(ev) => {
      ev.stopPropagation()
      setMyFeedback('no')
    }} title={feedbackUsers['no'].join('\n')}>
      <NoIcon />
      <span>{feedbackCounts['no']}</span>
    </div>,
    <div key="maybe" className={"feedback-item " + activeClass('maybe')} onClick={(ev) => {
      ev.stopPropagation()
      setMyFeedback('maybe')
    }} title={feedbackUsers['maybe'].join('\n')}>
      <MaybeIcon />
      <span>{feedbackCounts['maybe']}</span>
    </div>

  ]
  if (myFeedback) {
    feedbackBar.push((
      <div
        key="remove-feedback"
        className="ml-2 cursor-pointer"
        title="Remove my feedback"
        onClick={(ev) => {
          ev.stopPropagation()
          setMyFeedback(null)
        }}
      >
        <FaMinus />
      </div>
    ))
  }


  return (
    <div className={classnames("posting-person-card card px-3", {
      'twr-card': !!twr_id, 'selected': videoRecord?._id === _id
    })}>
      {showNumber &&
        <div className="number">
          {number}
        </div>
      }
      {groups.length > 0 && showRecordVideosModal &&  (
        <>
          {videoRecord?._id === _id ? (
              <div onClick={() => showRecordVideosModal(null)} className="play-button" >
                <CloseIconBlack />
              </div >
            ) : 
            (<div className="play-button" 
            onClick={() => { 
              showRecordVideosModal({
                _id,
                first_name,
                last_name
              }) 
              setActiveRidx(ridx)
            }}>
              <FaPlayCircle />
            </div>)
          }
        </>
      )}
      {topAvatar &&
        <ThumbImage
          src={avatar}
          isTwr={!!twr_id}
          className="avatar mt-1"
          onClick={() => { setShowFeedbackModal(true) }}
        />
      }
      <div
        className="card-body px-0 py-1"
        onClick={() => { setShowFeedbackModal(true) }}
      >
        <div className="content">
          <div className="card-title d-flex mb-0">
            <div className={classnames('w-100', {
              'text-danger': twr_id && !topAvatar && !showNumber
            })}>
              <h5 className="mb-1 d-flex align-items-center">
                {first_name} {last_name}
                {twr_deleted && <div className="ml-auto pl-2 mr-2 h6">
                  <small>Deleted</small>
                </div>}
              </h5>
              <div className="mb-2">
                <p className="card-text mb-0 actual-call-section">
                  <span>Actual Call:</span>
                  <strong className="mx-2">{formatHour(actual_call)}</strong>
                </p>
                <p className="card-text mb-0">
                  <span>Role:</span>
                  <strong className="ml-2">{role}</strong>
                </p>
                <label className={hideContact ? "d-none": "mb-0"} onClick={(ev) => {
                  ev.stopPropagation()
                  setShowContact(!showContact)
                }}>Contact</label>
                {showContact &&
                <div>
                  <p className="card-text mb-0">
                    <span>Phone:</span>
                    <strong className="ml-2">{phone}</strong>
                  </p>
                  <p className="card-text mb-0">
                    <span>Email:</span>
                    <strong className="ml-2">{email}</strong>
                  </p>
                  <p className="card-text mb-0">
                    <span>Agent:</span>
                    <strong className="ml-2">{agent}</strong>
                  </p>
                </div>}
              </div>
            </div>
            {skipped && !USER_TYPE.IS_CLIENT() && false && <small>&nbsp;&nbsp;skipped</small>}
            <span className="ml-auto myfeedback-icon mt-1">
              {MyFeedbackIcon}
            </span>
          </div>
          {!hideFeedbackComments && POSTINGPAGE_PERMISSIONS.CAN_LEAVE_FEEDBACK() && (
            <div className="d-flex align-items-start">
              {feedbackBar}
              <div
                data-id={_id}
                className={"commentor ml-auto " + (commentRelateClick ? '' : 'main-commentor')} onClick={(ev) => {
                  ev.stopPropagation()
                  if (commentRelateClick) {
                    const elem = document.querySelector(`.main-commentor[data-id="${_id}"]`)
                    elem.click()
                  } else {
                    setCommentsVisible(true)
                  }
                }}
              >
                <FaComment className="ml-2 no-print" />
                <span className="ml-1 no-print">{cmts.length}</span>
                <div className="print-only">
                  {cmts.map((comment, idx) => (
                    <div className="mb-1" key={idx}>
                      <label className="mb-0">{comment.by.email}</label>
                      <p className="mb-0">
                        <em>{comment.content}</em>
                      </p>
                    </div>
                  ))}
                  {cmts.length === 0 && (
                    <div>
                      No comments.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {showCommentInline && POSTINGPAGE_PERMISSIONS.CAN_LEAVE_FEEDBACK() && (
            <div onClick={ev => {
              ev.stopPropagation()
            }} className="d-flex flex-column">
              {cmts.map((comment, idx) => (
                <div className="mb-1" key={idx}>
                  <label className="mb-0"> - {comment.by.email} - </label>
                  <p className="mb-0">
                    {comment.content}
                  </p>
                </div>
              ))}
              {cmts.length === 0 && (
                <div>
                  No comments.
                </div>
              )}
              {!disableFeedbackCommentsEdit && ([
                <textarea
                  key='textarea'
                  className="form-control"
                  rows={1}
                  value={content}
                  onChange={ev => setContent(ev.target.value)}
                  placeholder="New comment here"
                ></textarea>,
                <button
                  key='submit'
                  className="btn btn-text btn-sm ml-auto mr-3"
                  onClick={addNewComment}
                  disabled={!content}
                  title="Send"
                >
                  <FaPaperPlane />
                </button>
              ])}
            </div>
          )}
        </div>
        {!topAvatar &&
          <ThumbImage
            isTwr={!!twr_id}
            src={avatar}
            className="small-avatar mt-1"
          />
        }
      </div>
      {POSTINGPAGE_PERMISSIONS.CAN_LEAVE_FEEDBACK() && (
        [
          <Modal
            key="comment-modal"
            show={commentsVisible}
            onHide={() => setCommentsVisible(false)}
          >
            <Modal.Header closeButton>
              <h5>Comments</h5>
            </Modal.Header>
            <Modal.Body>
              {cmts.map((comment, idx) => (
                <div key={idx}>
                  <label>{comment.by.email}</label>
                  <p>{comment.content}</p>
                </div>
              ))}
              {cmts.length === 0 && (
                <div>
                  No comments yet.
                </div>
              )}
            </Modal.Body>
            {!disableFeedbackCommentsEdit && (
            <Modal.Footer>
              <textarea
                className="form-control"
                value={content}
                onChange={ev => setContent(ev.target.value)}
              ></textarea>
              <button
                className="btn btn-danger"
                onClick={addNewComment}
                disabled={!content}
              >
                Comment
              </button>
            </Modal.Footer>)}
          </Modal>
          ,
          <TalentInfoModal
            key={_id}
            _id={_id}
            showTalentInfoModal={showFeedbackModal}
            setShowTalentInfoModal={setShowFeedbackModal}
            agent={agent}
            email={email}
            first_name={first_name}
            last_name={last_name}
            logo={avatar}
            phone={phone}
            role={role}
            hideContact={hideContact}
            studio={studio}
            hideFeedbackComments={hideFeedbackComments}
            MyFeedbackIcon={MyFeedbackIcon}
            feedbackBar={feedbackBar}
            cmts={cmts}
            content={content}
            setContent={setContent}
            addNewComment={addNewComment}
            hideCommentSection={false}
            photos={photos}
            agent_email={agent_email}
            height={height}
            agent_phone={agent_phone}
            instagram_handle={instagram_handle}
            tiktok_handle={tiktok_handle}
            clothes_type={clothes_type}
            clothes_size_object={clothes_size_object} />
        ]
      )}
    </div>
  )
}

export default SessionPersonCard
