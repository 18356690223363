import React, { useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Modal, Accordion, Card, Container, Row, Col, Form, Button } from 'react-bootstrap'
import DateTimePicker from "react-datetime-picker";
import { FaEye, FaListAlt, FaTimes, FaTrashAlt, FaUpload } from "react-icons/fa";
import _ from 'lodash'
import { static_root, uploadFile, searchUsers, getOneSession, getUser,
  updateSession, createSession } from "../../services";
import { TINYMCE_KEY, SESSION_LOCATION_TYPE, SESSION_TIME_TYPE, SESSION_BOOK_TYPE, USER_TYPES } from "../../constants";
import { update as updateStudioInStore } from '../../store/studios'
import { toggleLoadingState } from '../../utils'

let fnTimeoutHandler = null;
let openSendEmail = false;

const FileField = ({ label, value, disabled, setValue, toggleLoadingState }) => {
  const inputRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  return (
    <div>
      <div className="d-flex align-items-center file-upload-field">
        <div className="file-upload-input-wrapper mr-3">
          {!disabled && (
            <Button disabled={uploading} size="sm" variant="text" onClick={() => {
              inputRef.current.click()
            }}>
              <FaUpload />
            </Button>
          )}
          <input
            ref={inputRef}
            type="file"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
            className="form-control mb-3 d-none"
            onChange={(ev) => {
              toggleLoadingState(true)
              setUploading(true)
              uploadFile(ev.target.files[0]).then(data => {
                setValue((value || []).concat(data.key || data.Key))
                setUploading(false)
                toggleLoadingState(false)
              })
            }}
          />
        </div>
        <label className="mb-0 mr-2">
          {label}
        </label>
      </div>
      {(value || []).map((v, vIdx) => {
        return (
          <div key={v + vIdx} className='d-flex align-items-center ml-4'>
            <span className="text-secondary"><b>{vIdx + 1}:</b> {v.slice(7)}</span>
            <small className="ml-2 cursor-pointer" onClick={() => {
              window.open(`${static_root}${v}`)
            }}><FaEye /></small>
            <small className="ml-2 cursor-pointer" onClick={() => {
              setValue(value.filter((v, vi) => vi !== vIdx))
            }} ><FaTrashAlt /></small>
          </div>
        )
      })}
    </div>
  )
}

export default ({
  show,
  session,
  studio,
  onHide,
  showSendEmail = false
}) => {
  const [dateIndex, setDateIndex] = useState(1)
  const [loadingSessionUsers, setLoadingSessionUsers] = useState(false);
  const [sessionUsers, setSessionUsers] = useState([]);
  const [dates, setDates] = useState([])
  const [sessionName, setSessionName] = useState('Session')
  const user = getUser()

  const isAdmin = [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type)

  const dispatch = useDispatch()

  const searchSessionUsers = async (email) => {
    if (fnTimeoutHandler) {
      clearTimeout(fnTimeoutHandler);
    }
    fnTimeoutHandler = setTimeout(async () => {
      setLoadingSessionUsers(true);
      const sessionUsers = await searchUsers(email, [
        USER_TYPES.SESSION_MANAGER,
        USER_TYPES.CASTING_DIRECTOR,
        USER_TYPES.SUPER_ADMIN,
      ]);
      setSessionUsers(sessionUsers);
      setLoadingSessionUsers(false);
    }, 1000);
  };

  const setDateField = (idx, field, value) => {
    const temp = _.cloneDeep(dates)
    temp[idx][field] = value
    setDates(temp)
  };

  const removeDate = (idx) => {
    const temp = [...dates]
    temp.splice(idx, 1)
    setDates(temp)
  };

  const onAddDateClick = () => {
    setDates([
      ...dates,
      {
        managers: [],
        lobbyManager: [],
        start_time: '',
        book_status: "Book"
      }
    ])
    setDateIndex(dates.length + 1)
  }
  const onSubmit = (e) => {
    e.preventDefault()
    const buff = new FormData(e.target)
    const formData = new FormData()
    formData.append('name', buff.get('name'))
    formData.append('twr', buff.get('twr'))

    formData.append('dates', JSON.stringify(dates))

    if (session) {
      updateSession(session._id, formData).then(res=>{
        if (studio.sessions) {
          const idx = studio.sessions.findIndex(it=>it._id == res._id)
          const sessions = [...studio.sessions]
          sessions[idx] = res
          const temp = {...studio, sessions}
          dispatch(updateStudioInStore(temp))
          onHide(res, openSendEmail)
        } else {
          onHide()
        }
      })
    } else {
      formData.append('studio', studio._id)
      createSession(formData).then(res=>{
        if (studio.sessions) {
          const sessions = [...studio.sessions, res]
          const temp = {...studio, sessions}
          dispatch(updateStudioInStore(temp))
          onHide(res, openSendEmail)
        } else {
          onHide()
        }
      })
    }
  }

  const onModalOpen = async () => {
    try {
      if (session) {
        const res = await getOneSession(session._id)
        setSessionName(session.name)
        setDates(res.dates)
      }
      
    } catch (error) {
      
    }
    
  }
  
  useEffect(() => {
    setSessionName('Session')
  }, [dates])

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEnter={onModalOpen}
      dialogClassName="session-cr-modal"
      size="xl"
    >
      <Form
        className="d-flex flex-column h-100"
        onSubmit={onSubmit}
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">
            {studio ? studio.name: ''}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="name"
                    value={sessionName}
                    onChange={ev => {
                      setSessionName(ev.target.value)
                    }}
                    placeholder="Session Name"
                    disabled={!isAdmin}
                  />
                </Form.Group>
                <Accordion activeKey={dateIndex}>
                  {dates.map((oneDate, idx) => {
                    const isInPerson = [SESSION_LOCATION_TYPE[1], SESSION_LOCATION_TYPE[2]].includes(oneDate.location_type)
                    return (
                      <Card key={idx}>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={idx + 1}
                        >
                          <div className="d-flex justify-content-between">
                            <label
                              className="mb-0 cursor-pointer"
                              onClick={() => {setDateIndex(dateIndex === (idx + 1) ? -1 : (idx + 1))}}
                            >
                              <span className="mr-3">
                                Day {idx + 1}:
                              </span>
                              {`${ moment(oneDate.start_time).format('M/D/YYYY h:m A z')}`} / {oneDate.book_status}
                            </label>
                            {isAdmin && (
                              <label
                                className="mb-0 mt-n1 cursor-pointer"
                                onClick={() => {
                                  removeDate(idx);
                                  setDateIndex(-1)
                                }}
                              >
                                <FaTimes />
                              </label>
                            )}
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={idx + 1}>
                          <Card.Body>
                            <div className="d-flex">
                              <div className="mr-3">
                                <div className="d-flex mb-3">
                                  <DateTimePicker
                                    ref={rf => {
                                      if (rf) {
                                        const el = ReactDOM.findDOMNode(rf)
                                        el.tabIndex = -1
                                      }
                                    }}
                                    disabled={!isAdmin}
                                    value={oneDate.start_time && new Date(oneDate.start_time)}
                                    className="form-control mr-2"
                                    onChange={(value) => {
                                      setDateField(idx, "start_time", value);
                                    }}
                                  />
                                  <Form.Control
                                    as="select"
                                    className="mr-2"
                                    disabled={!isAdmin}
                                    defaultValue={oneDate.book_status}
                                    onChange={(ev) => {
                                      setDateField(idx, "book_status", ev.target.value);
                                    }}
                                  >
                                    {SESSION_BOOK_TYPE.map((type) => (
                                      <option key={type}>{type}</option>
                                    ))}
                                  </Form.Control>
                                </div>
                                <div className="d-flex mb-3">
                                  <Form.Control
                                    as="select"
                                    className={isInPerson ? "mr-2": ""}
                                    value={oneDate.location_type}
                                    disabled={!isAdmin}
                                    onChange={ev => {
                                      setDateField(idx, "location_type", ev.target.value)
                                    }}
                                  >
                                    {SESSION_LOCATION_TYPE.map(lt => {
                                      return <option key={lt}>{lt}</option>
                                    })}
                                  </Form.Control>
                                  {isInPerson && (
                                    <Form.Control
                                      value={oneDate.in_person_location}
                                      placeholder="In Person Location"
                                      style={{ flex: '300%' }}
                                      disabled={!isAdmin}
                                      onChange={ev => {
                                        setDateField(idx, "in_person_location", ev.target.value)
                                      }}
                                    />
                                  )}
                                </div>
                                <AsyncTypeahead
                                  id="session-user-select"
                                  className="mb-1"
                                  selected={oneDate.managers}
                                  multiple
                                  disabled={!isAdmin}
                                  onChange={(value) => {
                                    setDateField(idx, "managers", value);
                                  }}
                                  isLoading={loadingSessionUsers}
                                  labelKey="email"
                                  minLength={2}
                                  onSearch={searchSessionUsers}
                                  options={sessionUsers}
                                  placeholder="Search for a Session Manager..."
                                />
                                <AsyncTypeahead
                                  id="lobby-manager-select"
                                  className="mb-1"
                                  selected={oneDate.lobbyManager}
                                  multiple
                                  disabled={!isAdmin}
                                  onChange={(value) => {
                                    setDateField(idx, "lobbyManager", value);
                                  }}
                                  isLoading={loadingSessionUsers}
                                  labelKey="email"
                                  minLength={2}
                                  onSearch={searchSessionUsers}
                                  options={sessionUsers}
                                  placeholder="Search for a Lobby Manager..."
                                />

                                <div className="d-flex">
                                  <div className="w-50">
                                    <FileField
                                      label='Sizecard File'
                                      value={oneDate.size_card_pdf}
                                      setValue={v => {
                                        setDateField(idx, "size_card_pdf", v);
                                      }}
                                      toggleLoadingState={toggleLoadingState}
                                    />
                                    <FileField
                                      label='Schedule File'
                                      value={oneDate.schedule_pdf}
                                      setValue={v => {
                                        setDateField(idx, "schedule_pdf", v);
                                      }}
                                      toggleLoadingState={toggleLoadingState}
                                    />
                                    <FileField
                                      label='Storyboard File'
                                      value={oneDate.storyboard_pdf}
                                      setValue={v => {
                                        setDateField(idx, "storyboard_pdf", v);
                                      }}
                                      toggleLoadingState={toggleLoadingState}
                                    />
                                  </div>
                                  <div className="w-50">
                                    <FileField
                                      label='Script File'
                                      value={oneDate.script_pdf}
                                      setValue={v => {
                                        setDateField(idx, "script_pdf", v);
                                      }}
                                      toggleLoadingState={toggleLoadingState}
                                    />
                                    <label className="d-flex align-items-center mb-1 ml-5">
                                      <input disabled={!isAdmin} defaultChecked={!!oneDate.show_to_talent} type="checkbox" name="show_to_talent" className="mr-2" onChange={ev => {
                                        setDateField(idx, 'show_to_talent', ev.target.checked)
                                      }} />
                                      <span>Show To Talent</span>
                                    </label>
                                    <FileField
                                      label='Treatment File'
                                      value={oneDate.treatment_pdf}
                                      setValue={v => {
                                        setDateField(idx, "treatment_pdf", v);
                                      }}
                                      toggleLoadingState={toggleLoadingState}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="d-flex">
                                  <label>Casting Notes</label>
                                  <label className="d-flex align-items-center ml-4">
                                    <input disabled={!isAdmin} defaultChecked={!!oneDate.show_description_to_talent} type="checkbox" name="show_description_to_talent" className="mr-2" onChange={ev => {
                                      setDateField(idx, 'show_description_to_talent', ev.target.checked)
                                    }} />
                                    <span>Show To Talent</span>
                                  </label>
                                </div>
                                <Editor
                                  apiKey={TINYMCE_KEY}
                                  init={{
                                    height: '50vh',
                                    menubar: false,
                                    plugins: [
                                      'advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                                  value={oneDate.description}
                                  onEditorChange={content => {
                                    setDateField(idx, "description", content)
                                  }}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
                </Accordion>
                {[USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type) && (
                  <p
                    className="mb-4 mt-1 cursor-pointer mr-5 font-weight-bold h6"
                    onClick={onAddDateClick}>
                    + Add Date
                  </p>
                )}
                <label>
                  The waitingroom integration.
                  <FaListAlt size="11" className="ml-2" />
                </label>
                <input
                  type="text"
                  name="twr"
                  disabled={!isAdmin}
                  defaultValue={_.get(session, 'twr', '')}
                  placeholder="Input room_id/studio_uri with no space."
                  className="form-control"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        {showSendEmail && (
          <div className="d-flex align-items-center mr-auto">
            <Button
              variant="primary"
              type="submit" className="text-nowrap"
              onClick={() => { openSendEmail = openSendEmail || dates[0].start_time }}
            >
              Submit & Send Email
            </Button>
            <span className="mx-2">For</span>
            <select className="mr-auto form-control" onChange={ev => {
              openSendEmail = ev.target.value
            }}>
              {dates.map((date, dIdx) => {
                return <option key={`${dIdx}-${date.start_time}`} value={date.start_time}>
                  {`${ moment(date.start_time).format('M/D')}`}
                </option>
              })}
            </select>
          </div>
        )}
        <Button
          variant="primary"
          type="submit"
        >
          Submit
        </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}